



















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Observer
@Component({})
export default class PoolCountdown extends Vue {
  @Prop() time!: string
  @Prop({ default: '' }) statusColor!: string
  @Prop({ default: false }) forceIsoStringDate
  @Prop() callback!: () => void
  @Prop({ default: false }) isDetailPoolCowndown!: () => void
  @Prop({ default: false }) forcePrimaryText!: boolean
  countDown = ''
  @Watch('time', { immediate: true }) onTypeChange(): void {
    this.init()
  }

  init(): void {
    const x = setInterval(() => {
      const currentTime = Number(moment().format('x'))
      const distance = Number(moment(this.time).format('x')) - currentTime
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      // const hours = Math.floor(distance / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      // const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      // if (days > 0) this.countDown = `${days} day${days > 1 ? 's' : ''}`
      // else this.countDown = `${hours}h : ${minutes}m`
      this.countDown = `${days}D : ${hours}H : ${minutes}M`
      if (distance < 1) {
        clearInterval(x)
        if (this.callback) this.callback()
        this.countDown = ''
      }
    }, 1000)
  }
}
