var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'primary--text': _vm.statusColor === 'primary' || _vm.statusColor === 'grey-lighten' || _vm.forcePrimaryText,
    'error--text': _vm.statusColor === 'error',
    'footer-project-card--text text--lighten-4': _vm.statusColor === 'violet-lighten' && !_vm.forcePrimaryText,
    'footer-project-card--text text--lighten-3': _vm.statusColor === 'blue-lighten' && !_vm.forcePrimaryText,
    'footer-project-card--text text--lighten-2': _vm.statusColor === 'blue-lighten-2' && !_vm.forcePrimaryText,
    'cowndown-text-small': _vm.isDetailPoolCowndown,
    'cowndown-text-default': !_vm.isDetailPoolCowndown,
  }},[(!_vm.forceIsoStringDate)?_c('span',[_vm._v(" "+_vm._s(_vm.countDown)+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("yyyy.mm.dd")(_vm.time)))])])}
var staticRenderFns = []

export { render, staticRenderFns }