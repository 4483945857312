






































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import ProjectCardCountDown from './project-card-countdown.vue'
import { PoolStore } from '@/stores/pool-store'
@Observer
@Component({
  components: {
    ProjectCardCountDown,
  },
})
export default class App extends Vue {
  @Prop({}) project!: PoolStore
  @Prop({ default: 1 }) number!: number

  get poolStatus() {
    return this.project?.poolState?.status
  }
  get statusColor() {
    return this.project?.poolState?.statusColor
  }
  get poolTargetTime() {
    return this.project?.poolState?.targetTime
  }
  get transformStatusText() {
    return this.project?.poolState?.transformStatusText
  }
}
